import React from 'react';
import { main } from './ErrorPage.css';

// class Privacy extends React.Component {
export default function ErrorPage() {

  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }

  // render() {
    return (
      <div className={main}>
        <h3>ERROR</h3>
      </div>
    );
  // }
}

