import React from 'react';
import { main } from './Privacy.css';

// class Privacy extends React.Component {
export default function Privacy() {

  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }

  // render() {
    return (
      <div className={main}>
        <h3>Privacy Policy</h3>
        <p>This policy applies to all information collected or submitted on Dimenschen’s website and our apps for iPhone and any other devices and platforms.</p>
        <h5>Information we collect</h5>
        <p>
          {
            `When creating an account, you will be 
            asked to enter a username, an email address 
            and a password. Email addresses are used for 
            logging in, password resets, responding to 
            emails that you initiate, and notifications 
            that you request.\n\n
            We store information about your submitted 
            beliefs and the urls you mark as completed to sync 
            this information between all apps. We also 
            collect anonymous statistics about which 
            content is most popular to help inform our
            recommendation engine.`
          }
        </p>
        <h6>Technical basics</h6>
        <p>
          {
            `If you enable notifications, we must 
            store a token to send them.\n\n
            If you upload files to Dimenschen, we need 
            to store them until you delete them.\n\n
            We use cookies on the site and similar tokens 
            in the app to keep you logged in. 
            Our server software may also store basic 
            technical information, such as your IP
            address, in temporary memory or logs.`
          }
        </p>
        <h6>Analytics</h6>
        <p>
          {
            `Dimenschen’s app collects aggregate statistics, 
            such as the percentage of users who use particular 
            features in order to improve the app.`
          }
        </p>
        <h5>Information usage</h5>
        <p>
          {
            `We use the information we collect to operate and 
            improve our website, apps, and customer support.\n\n
            We do not share personal information with outside 
            parties. We may share anonymous, aggregate statistics 
            with outside parties, such as how many people have 
            read a particular article or watched a particular 
            film. We may share user in-app behavior data with other 
            users at the community level, like when a user creates 
            a belief or marks a url as completed, other users may be
            notified.\n\n
            We may disclose your information in response to 
            subpoenas, court orders, or other legal requirements; 
            to exercise our legal rights or defend against legal 
            claims; to investigate, prevent, or take action 
            regarding illegal activities, suspected fraud or 
            abuse, violations of our policies; or to protect our 
            rights and property.\n\n
            In the future, we may sell to, buy, merge with, or 
            partner with other businesses. In such transactions, 
            user information may be among the transferred assets.\n\n`
          }
        </p>
        <h5>Security</h5>
        <p>
          {
            `We implement a variety of security measures to help keep 
            your information secure. For instance, all communication 
            with the app and website requires HTTPS with certificate 
            pinning. Passwords are hashed, not stored, using 
            industry-standard methods.`
          }
        </p>
        <h5>Deleting your information</h5>
        <p>
          {
            `You may delete your account from the Dimenschen iOS 
            app.\n\n
            Deleted information may be kept in backups for up to 
            90 days. Backups are encrypted and are only accessed 
            if needed for disaster recovery.`
          }
        </p>
        <h5>Third-party links and content</h5>
        <p>
          {
            `Dimenschen displays links and content from third-party 
            rss feeds and websites. These have their own independent 
            privacy policies, and we have no responsibility or 
            liability for their content or activities.`
          }
        </p>
        <h5>California Online Privacy Protection Act Compliance</h5>
        <p>
          {
            `We comply with the California Online Privacy Protection 
            Act. We therefore will not distribute your personal 
            information to outside parties without your consent.`
          }
        </p>
        <h5>Children’s Online Privacy Protection Act Compliance</h5>
        <p>
          {
            `We never collect or maintain information at our website 
            from those we know are under 13, and no part of 
            our website is structured to attract anyone under 13.`
          }
        </p>
        <h5>Information for European Union Customers</h5>
        <p>
          {
            `By using Dimenschen and providing your information, 
            you authorize us to collect, use, and store your 
            information outside of the European Union.`
          }
        </p>
        <h5>International Transfers of Information</h5>
        <p>
          {
            `Information may be processed, stored, and used outside 
            of the country in which you are located. Data privacy 
            laws vary across jurisdictions, and different laws may 
            be applicable to your data depending on where it is 
            processed, stored, or used.`
          }
        </p>
        <h5>Your Consent</h5>
        <p>
          {
            'By using our site or apps, you consent to our privacy policy.'
          }
        </p>
        <h5>Contacting Us</h5>
        <p>
          {
            `If you have questions regarding this privacy policy, you may 
            email support@dimenschen.com. Please note that account 
            deletion should be done within the Dimenschen app, not via 
            email requests, for security reasons.`
          }
        </p>
        <h5>Changes to this policy</h5>
        <p>
          {
            `If we decide to change our privacy policy, we will post those 
            changes on this page. Summary of changes so far:`
          }
        </p>
        <ul>
          <li>May 25, 2019: First published.</li>
          <li>December 05, 2022: First published.</li>
        </ul>
      </div>
    );
  // }
}

