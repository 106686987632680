import React, { useState, useEffect } from 'react';
import './App.css';
import Proposition from '../../components/Proposition/Proposition';
import { get } from '../../actions/api';

const userId = '6391f513cf06dc10a5d50dd2';

function App() {
  const [concepts, setConcepts] = useState([]);
  const [propositions, setPropositions] = useState([]);

  const getRecentlyCreated = async () => {
    const res = await get(`beliefs/list/recently_created?user=${userId}`)
    .catch(err => {
      console.log(err);
      return;
    });
    setPropositions(res.beliefs);
    setConcepts(res.trendingTopics);

  }

  // listen for someone using search bar
  useEffect(async () => {
    await getRecentlyCreated();

    const keyDownHandler = async event => {

      if (event.key === 'Enter' && event.target.value !== '') {
        event.preventDefault();

        const res = await get(`beliefs/search?search=${event.target.value}&user=${userId}`)
        .catch(err => {
          console.log(err);
          return;
        });
    
        if (res.beliefs.length) setPropositions(res.beliefs);
        else await getRecentlyCreated();

      } else if (event.key === 'Enter' && event.target.value === '') {
        await getRecentlyCreated();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="App">
        <input 
          type="text"
          placeholder="Search Dimenschen"
        />
        {
          concepts.length
          ?
          <div>
            <p 
            style={{
              fontSize: '.8em',
            }}
            >Trending: 
              {
                concepts.map((concept, idx) => {
                  return ` ${concept}${idx !== concepts.length -1 ? ',' : '.'}`;
                })
              }
            </p>
          </div>
          :
          null
        }
        {
          propositions.length
          ?
          <div>
            {
              propositions.map((prop, idx) =>{
                return <Proposition key={idx} prop={prop} />
              })
            }
          </div>
          :
          null
        }
    </div>
  );
}

export default App;
