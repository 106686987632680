const axios = require('axios');
const R = require('ramda');
const host = require('../constants/config').api
// const API_VERSION = require('../constants/config').API_VERSION


const globalConfig = {
  headers: { 
    // 'Accept-Version': API_VERSION,
  }
};

/**
 * custom rule for local handling for any routes pointed to new services 
 * production is routed via nginx
 */ 
function handleLocalhost(api, path) {
  if (path.indexOf('users/') > -1) {
    return api.replace('3000', '3001');
  }
  if (path.indexOf('beliefs/') > -1 && path.indexOf('stories/beliefs') === -1) {
    return api.replace('3000', '3002');
  }
  if (path.indexOf('stories/') > -1) {
    return api.replace('3000', '3003');
  }
  if (path.indexOf('notifications') > -1) {
    return api.replace('3000', '3004');
  }
  if (path.indexOf('hives') > -1) {
    return api.replace('3000', '3005');
  }
  return api;
}

export async function post(path, payload, config = {}) {
  let apiHost = host;
  apiHost = handleLocalhost(apiHost, path);
  const finalConfig = R.mergeDeepWith(R.concat, globalConfig, config);
  const response = await axios.post(apiHost + path, payload, finalConfig)
    .catch((err) => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

export async function get(route, config = {}) {
  let apiHost = host;
  apiHost = handleLocalhost(apiHost, route);
  const finalConfig = R.mergeDeepWith(R.concat, globalConfig, config);
  const fullRoute = apiHost + route;
  const response = await axios.get(fullRoute, finalConfig)
    .catch(err => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

export async function patch(path, payload, config = {}) {
  let apiHost = host;
  apiHost = handleLocalhost(apiHost, path);
  const finalConfig = R.mergeDeepWith(R.concat, globalConfig, config);
  const response = await axios.patch(apiHost + path, payload, finalConfig)
    .catch(err => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

// module.exports = { post, get, patch };