import * as React from 'react';
import './Proposition.css';
// import { main, black, white } from '../../constants/theme';
// import Slider from '@mui/material/Slider';
// import Typography from '@mui/material/Typography';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import RedditIcon from '@mui/icons-material/Reddit';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import PublicIcon from '@mui/icons-material/Public';
// import ReplyIcon from '@mui/icons-material/Reply';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function Proposition({prop}) {
  // const [value, setValue] = React.useState(prop.etvSummary.usersETVvalue || 50);


  return (
    <div className='body'>
      <p>{prop.text}</p>
      {
        prop.parentMetadata 
        ?
        <div
          style={{
            width: '100%',
            padding: '1em',
            margin: '8px 0',
            /* text-align: center; */
            /* box-sizing: border-box; */
            border: '2px solid #F6F5BF',
            borderRadius: '0.5em',
            /* background-color: #F6F5BF; */
            color: '#F6F5BF'
          }}
        >
          <p>{prop.parentMetadata.text}</p>
          {/* <ReplyIcon className='icon' />{prop.children.length} */}
        </div>
        :
        null
      }
      {/* <div className="slider">
        <Slider
          defaultValue={prop.etvSummary.usersETVvalue}
          value={value}
          step={1}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          onChange={handleChange}
          aria-labelledby="non-linear-slider"
          sx={{
            color: main,
          }}
        />
        <Typography id="non-linear-slider" gutterBottom sx={{ textAlign: 'center' }}>
          Truth: {valueLabelFormat((value))}
        </Typography>
      </div> */}
      {/* <p className="truth">{prop.etvSummary.usersETVvalue}% true</p> */}
      {/* <p>ratings: {prop.etvs.length}</p> */}
      <div className="row">
        <span><PublicIcon className='icon'/>{` ${prop.etvSummary.avgETV}%`}</span>
        {/* <span><ReplyIcon className='icon' />{prop.children.length}</span> */}
        {/* <span><FavoriteBorderIcon className='icon'/>{prop.piques.length}</span> */}
        {/* <span><MoreHorizIcon className='icon' /></span> */}
        <span><TwitterIcon className='icon' onClick={() => window.open(`https://twitter.com/search?q=${prop.text.replace(/ /g, '%20')}&src=typed_query`)} target="_blank" /></span>
        <span><YouTubeIcon className='icon' onClick={() => window.open(`https://www.youtube.com/results?search_query=${prop.text.replace(/ /g, '+')}`)} target="_blank" /></span>
        <span><RedditIcon className='icon' onClick={() => window.open(`https://www.reddit.com/search?q=${prop.text.replace(/ /g, '+')}`)} target="_blank" /></span>
        <span><PinterestIcon className='icon' onClick={() => window.open(`https://www.pinterest.com/search/pins/?q=${prop.text.replace(/ /g, '%20')}&src=typed_query`)} target="_blank" /></span>
        <span><GoogleIcon className='icon' onClick={() => window.open(`https://www.google.com/search?q=${prop.text.replace(/ /g, '+')}`)} target="_blank" /></span>
      </div>
      {/* <p>{prop.created_date}</p> */}
      {/* <FontAwesomeIcon className='' icon="fa-brands fa-wikipedia-w /> */}
      {/* dictionary */}
      {/* etymology  */}
      {/* Diffbot lookup results? Where does this appear in articles?*/}
      {/* Scientific Article aggregator? Xiv / google scholar / else? */}
      {/* Google Books advanced search https://www.google.com/search?tbo=p&tbm=bks&q=%22god+is+dead+and+it+is+we+who+have+killed+him%22&tbs=,bkt:b&num=10*/}
      {/* ngram viewer API https://jameshfisher.com/2018/11/25/google-ngram-api/*/}
      {/** chat GTP plug in // generate image via stable diffusion / dalle etc */}
    </div>
  );
}